<template>
    <v-dialog :persistent="creatingInstance" v-model="createInstanceDialog" width="700" scrollable height="600" v-if="!isSpaceArchived">
        <template v-slot:activator="{ on }">
            <div v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card flat>
            <v-card-title>
                <div class="d-flex align-center justify-space-between secondary--text w-100">
                    <div>
                        <v-icon class="mr-1">add</v-icon>
                        New Instance Creation
                    </div>
                    <div>
                        <v-btn :disabled="creatingInstance" icon @click="createInstanceDialog = false"><v-icon>clear</v-icon></v-btn>
                    </div>
                </div>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <div>
                <div v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE" class="pa-5">
                    <v-alert type="info">
                        <span>
                            If creating group instances, please
                            <a class="white--text" href="https://docs.nuvolos.cloud/user-guides/education-guides/setting-up-group-projects" target="_blank">
                                read our recommendation
                            </a>
                            for best-practices to handle groups.
                        </span>
                    </v-alert>
                </div>
                <transition :duration="150">
                    <v-stepper style="box-shadow: none; padding-bottom: 0" v-model="currentStep" v-if="createInstanceDialog" vertical>
                        <v-stepper-step :color="currentStep > 1 ? 'primary' : 'secondary'" :complete="currentStep > 1" step="1">
                            Instance content
                        </v-stepper-step>
                        <v-stepper-content step="1">
                            <div class="pa-3">
                                <v-radio-group class="ma-0 pa-0" v-model="instanceContent">
                                    <v-radio label="Empty instance" :value="instanceContentOptions.EMPTY_INSTANCE"></v-radio>
                                    <v-radio label="Create from snapshot" :value="instanceContentOptions.CREATE_FROM_SNAPSHOT"></v-radio>
                                </v-radio-group>
                                <div v-if="instanceContent === instanceContentOptions.EMPTY_INSTANCE">
                                    <v-alert border="left" colored-border type="info">
                                        <div class="d-flex flex-column">
                                            <span class="font-weight-bold">Empty Instance Creation</span>
                                            The instance you will create will have no contents (files, tables, or applications). You can, however, share content
                                            with the instance anytime in the future.
                                        </div>
                                    </v-alert>
                                </div>
                                <div v-else-if="instanceContent === instanceContentOptions.CREATE_FROM_SNAPSHOT">
                                    <v-alert colored-border border="left" type="info" v-if="selectedSnapshot.length">
                                        <p>
                                            The instance you will create will contain all the contents (files, tables, and applications) of the snapshot
                                            <span class="font-weight-bold">{{ selectedSnapshotName }}</span>
                                            .
                                        </p>
                                    </v-alert>
                                    <div class="d-flex align-center">
                                        <v-icon class="mr-1" small>group</v-icon>
                                        <span class="subtitle-1 font-weight-bold">Instance</span>
                                    </div>
                                    <v-select
                                        :items="instanceList"
                                        v-model="selectedInstance"
                                        @change="getInstanceData(false, false)"
                                        item-text="state"
                                        label="Select Instance"
                                        persistent-hint
                                        hint="Select the instance to choose a snapshot from"
                                        return-object
                                        single-line
                                        dense
                                        style="width: 350px"
                                        outlined></v-select>
                                    <v-data-table
                                        v-model="selectedSnapshot"
                                        show-select
                                        :headers="headers"
                                        :items="snapshots"
                                        :items-per-page="-1"
                                        :custom-sort="customSort"
                                        :sort-desc="true"
                                        item-key="snid"
                                        :sort-by="['snapshot_timestamp']"
                                        single-select
                                        :loading="instanceFetching"
                                        v-if="selectedInstance && snapshots.length"
                                        loading-text="Fetching instance data... Please wait">
                                        <template v-slot:[`item.long_id`]="{ item }">{{ item.long_id }}</template>
                                        <template v-slot:[`item.snapshot_timestamp`]="{ item }">{{ item.snapshot_timestamp | dateTimeToHuman }}</template>
                                    </v-data-table>
                                    <v-alert text prominent v-else-if="selectedInstance && !snapshots.length" type="warning">
                                        The selected instance does no contain snapshots.
                                    </v-alert>
                                </div>
                            </div>

                            <v-btn
                                :disabled="!instanceContent || (instanceContent === instanceContentOptions.CREATE_FROM_SNAPSHOT && !selectedSnapshot.length)"
                                color="primary"
                                @click="currentStep = 2">
                                continue
                            </v-btn>
                        </v-stepper-content>

                        <v-stepper-step :color="currentStep > 2 ? 'primary' : 'secondary'" :complete="currentStep > 2" step="2">Create instance</v-stepper-step>

                        <v-stepper-content step="2">
                            <v-form v-model="validSingleInstance" @submit.prevent="addInstance()">
                                <v-text-field
                                    label="Name of the instance"
                                    v-model="instanceName"
                                    :rules="[rules.nonEmpty]"
                                    required
                                    dense
                                    :disabled="creatingInstance"
                                    outlined
                                    class="mt-1"></v-text-field>
                                <ShortIdInput :disabledEdit="creatingInstance" :longId="instanceName" @input="updateShortId($event.nextValue)"></ShortIdInput>
                                <v-textarea
                                    v-model="instanceDescription"
                                    label="Description of the instance"
                                    rows="3"
                                    auto-grow
                                    :rules="[rules.nonEmpty]"
                                    required
                                    :disabled="creatingInstance"
                                    outlined
                                    class="mt-3"></v-textarea>
                            </v-form>
                            <v-btn :disabled="creatingInstance" @click="currentStep = 1" text outlined>Back</v-btn>
                        </v-stepper-content>
                    </v-stepper>
                </transition>
            </div>
            <v-card-text v-if="error">
                <v-alert color="error" icon="warning" class="mt-4" text>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold">{{ errorContent }}</span>
                        <span>
                            For more information on instance creation issues, check the troubleshooting documentation
                            <v-btn
                                class="font-weight-bold"
                                small
                                text
                                color="error"
                                href="https://docs.nuvolos.cloud/faqs/troubleshooting/administration-troubleshooting/i-cant-create-an-instance"
                                target="_blank">
                                here
                            </v-btn>
                        </span>
                    </div>
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    class="ml-1"
                    @click="addInstance()"
                    :loading="creatingInstance"
                    :disabled="!validSingleInstance || creatingInstance || currentStep < 2">
                    <v-icon small>add</v-icon>
                    Add Instance
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog persistent v-model="showGroupWarning" max-width="500">
            <v-card>
                <v-card-title><span class="title secondary--text">Separate group work</span></v-card-title>
                <v-card-text>
                    <p>
                        Are you creating a group instance?
                        <br />
                        If so, please consider creating a new space for groups instead of adding them to the current space:
                    </p>
                    <v-btn text color="secondary" href="https://docs.nuvolos.cloud/user-guides/education-guides/setting-up-group-projects" target="_blank">
                        <v-icon small class="mr-1">menu_book</v-icon>
                        <span class="overline font-weight-bold">See documentation</span>
                    </v-btn>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDialog" color="primary">Create a new space</v-btn>
                    <v-btn @click="skipDialog" color="primary" text>Create new instance</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { sortDateArray, sortArray, isCurrentState } from '@/utils'
import { enumsData } from '@/mixins/enums'
const ShortIdInput = () => import('@/components/ShortIdInput')
function defaultData() {
    return {
        currentStep: 1,
        instanceName: '',
        instanceShortName: '',
        instanceDescription: '',
        instanceCreationMode: 'multipleInstances',
        instanceContent: '',
        error: false,
        emailsInput: '',
        errorContent: 'An error has occurred',
        rules: {
            nonEmpty: p => p.length > 0 || 'Please provide a value.'
        },
        creatingInstance: false,
        validSingleInstance: false,
        selectedSnapshot: [],
        selectedInstance: null,
        createInstanceDialog: false,
        instanceContentOptions: {
            EMPTY_INSTANCE: 'emptyInstance',
            CREATE_FROM_SNAPSHOT: 'instanceFromSnapshot'
        },
        headers: [
            {
                text: 'Snapshot Name',
                align: 'left',
                value: 'long_id',
                sortable: true
            },
            {
                text: 'Creation Time',
                align: 'left',
                value: 'snapshot_timestamp',
                sortable: true
            }
        ],
        instanceFetching: false,
        snapshots: [],
        showGroupWarning: false
    }
}

export default {
    name: 'AddInstanceDialog',
    mixins: [enumsData],
    props: {
        isTextActivator: {
            type: Boolean,
            default: false
        },
        activatorText: {
            type: String,
            default: ''
        }
    },
    data() {
        return defaultData()
    },
    components: {
        ShortIdInput
    },
    methods: {
        customSort: function (items, index, isDesc) {
            if (items.length && index[0] === 'snapshot_timestamp' && !isDesc[0]) {
                return sortDateArray(items, 'snapshot_timestamp', 'ascending')
            } else if (items.length && index[0] === 'snapshot_timestamp' && isDesc[0]) {
                return sortDateArray(items, 'snapshot_timestamp', 'descending')
            } else if (items.length && !isDesc[0]) {
                return sortArray(items, 'long_id', 'ascending', true)
            } else if (items.length && isDesc[0]) {
                return sortArray(items, 'long_id', 'descending', true)
            }
        },
        updateShortId(newShortId) {
            this.instanceShortName = newShortId
        },
        fetchInstances() {
            this.$store.dispatch('spaceStore/fetchSpaceInstances', this.$route.params.sid)
        },
        completeInstanceCreation(invite) {
            if (invite) {
                this.$emit('inviteUsers', { instanceName: this.instanceName })
            }
            this.fetchInstances()
            this.createInstanceDialog = false
        },
        getInstanceData(instanceMetaData, snapshotMetaData) {
            this.selectedSnapshot = []
            let iid
            if (instanceMetaData) {
                iid = instanceMetaData.iid
                this.selectedInstance = instanceMetaData.long_id
            } else {
                iid = this.spaceInstances.filter(instance => instance.long_id === this.selectedInstance)[0].iid
            }
            this.instanceFetching = true
            this.$axios.get(`/instances/${iid}/snapshots`).then(response => {
                const snapshots = response.data.filter(snapshot => !isCurrentState(snapshot.short_id) && snapshot.lock_mode_name !== 'DELETING')
                this.snapshots = sortArray(snapshots, 'snid', 'descending', false)
                if (snapshotMetaData) {
                    this.selectedSnapshot = [snapshotMetaData]
                }
                this.instanceFetching = false
            })
        },
        addInstance() {
            if (!this.validSingleInstance || this.creatingInstance || this.currentStep < 2) return

            if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE && this.spaceInstances.length > 10) {
                this.showGroupWarning = true
            } else {
                this.skipDialog()
            }
        },
        closeDialog() {
            this.showGroupWarning = false
            this.$router.push({ name: 'home-dashboard', params: { oid: this.$route.params.oid } })
        },
        skipDialog() {
            this.showGroupWarning = false
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Creating instance, please wait...',
                snackBarTimeout: 10000,
                snackBarIcon: 'info'
            })
            this.error = false
            const postBody = {}
            let apiURL = ''
            if (this.instanceContent === this.instanceContentOptions.CREATE_FROM_SNAPSHOT && this.selectedSnapshot.length) {
                apiURL = '/spaces/' + this.$route.params.sid + '/instances/from_snapshot_async/' + this.selectedSnapshot[0].snid
            } else {
                apiURL = '/spaces/' + this.$route.params.sid + '/instances'
            }
            if (this.instanceName && this.instanceDescription) {
                postBody.description = this.instanceDescription
                postBody.long_id = this.instanceName
                postBody.short_id = this.instanceShortName
            }
            if (Object.keys(postBody).length) {
                this.creatingInstance = true
                this.$axios
                    .post(apiURL, postBody, { timeout: 300000 })
                    .then(response => {
                        if (this.instanceContent === this.instanceContentOptions.CREATE_FROM_SNAPSHOT) {
                            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
                            const successMessage = `Instance creation from snapshot started, you can track its progress <a href="${userTasksRoute}">here</a>`
                            this.$store.dispatch('showSnackBar', {
                                snackBarText: successMessage,
                                snackBarTimeout: 10000,
                                snackBarIcon: 'check_circle'
                            })
                        } else {
                            this.$store.dispatch('showSnackBar', {
                                snackBarText: 'Instance created successfully!',
                                snackBarTimeout: 5000,
                                snackBarIcon: 'check_circle'
                            })
                        }
                        this.createInstanceDialog = false
                        this.$store.dispatch('spaceStore/fetchSpaceInstances', this.$route.params.sid)
                    })
                    .catch(error => {
                        this.error = true
                        if (error?.response?.data?.reason[0].includes('duplicate key value violates unique constraint "uc_sid_short"')) {
                            this.errorContent = 'Another instance uses the short name you chose for this instance, please choose another name'
                        } else if (error?.response?.data?.reason[0].includes('duplicate key value violates unique constraint "uc_sid_long"')) {
                            this.errorContent =
                                'An instance associated with ' +
                                error.response.data.reason[0].match(/\d,.*/g)[0].replace(' already exists.', '').slice(3).replace(')', '') +
                                ' exists already'
                        } else {
                            this.$store.dispatch('showSnackBar', {
                                snackBarText: 'Failed to create the instance, please try again later.',
                                snackBarTimeout: 10000,
                                snackBarIcon: 'error'
                            })
                        }
                    })
                    .finally(() => {
                        this.creatingInstance = false
                    })
            }
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceInstances']),
        ...mapGetters('spaceStore', ['currentSpaceType', 'isSpaceArchived']),
        selectedSnapshotName() {
            const selected = this.selectedSnapshot
            if (selected.length) {
                return selected[0].long_id
            } else {
                return null
            }
        },
        instanceList() {
            const instances = this.spaceInstances.map(instance => instance.long_id)
            return instances
        }
    },
    watch: {
        createInstanceDialog: function (nextVal, preVal) {
            if (!nextVal && preVal) {
                Object.assign(this.$data, defaultData())
            } else if (nextVal) {
                this.$emit('dialogIsOpen', { value: true })
            }
        }
    }
}
</script>
